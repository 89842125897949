/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "vdx/components/MKButton";
import MKTypography from "vdx/components/MKTypography";

// Images
import logo from "vdx/assets/images/Logo sensibilisation VisioCyt-Screening_quadri.png";
import bgImage from "vdx/assets/images/visuel-screening-patient.svg";

function Header({ contactRef, studyLink }) {
  const executeScrollToContact = () => contactRef.current.scrollIntoView()    

  return (
    <MKBox height="auto">
      <MKBox display="flex" alignItems="center" height="140px">
        <Container sx={{ display: "flex", justifyContent:"space-between" }} >
          <MKBox component="img" src={logo} sx={{ width: { xs: "210px", sm: "321px" }}} />
          <MKBox display="flex" alignItems="center">
            <MKButton color="vdx_grey" size="large" onClick={executeScrollToContact}>Contact</MKButton>
          </MKBox>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0)
            )}, url(${bgImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: {
            xs: "right -200px bottom -10px", 
            sm: "right -300px bottom -10px", 
            md: "right -300px bottom -10px", 
            lg: "right -400px bottom -10px", 
            xl: "right -200px bottom -10px"},
          backgroundColor: "#fed23d",
        }}
        py={{ xs: 5, md: 15 }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h1"
              color="black"
              mb={3}
            >
              Un geste simple pour une grande cause
            </MKTypography>
            <MKTypography variant="body1" pr={6} mr={6}>
              Participez à notre étude clinique et contribuez au développement du <b>premier test de dépistage du 
              cancer de la vessie</b> en collectant <b>vos échantillons d’urine</b>.
            </MKTypography>
            <Grid container alignItems="center" sx={{ mt:3 }}>
              <Grid item xs={12} lg={6}>
                <MKButton color="vdx_green" size="large" onClick={() => window.open(studyLink, "_blank")}>
                  Participer à l'étude
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Header;
