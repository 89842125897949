// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "vdx/components/MKButton";
import MKTypography from "vdx/components/MKTypography";

// Coworking page component
import AboutUsOption from "vdx/components/AboutUsOption";

import gobeletIcon from "vdx/assets/images/gobelet.svg";
import discussionIcon from "vdx/assets/images/discussion.svg";
import medecinIcon from "vdx/assets/images/medecin.png";
import fumeurIcon from "vdx/assets/images/fumeur.png";

function PatientsPage({ eligibilityLink }) {
  return (
    <MKBox component="section" py={{ xs: 5, md: 15 }}
      sx={{
        backgroundColor: "#fafafa",
      }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <Stack spacing={{ xs: 2, sm: 8 }}>
              <MKTypography variant="h1" color="vdx_grey" my={1}>
                Suis-je éligible et que
                devrais-je faire ?
              </MKTypography>
              <MKBox
                display={{ xs: "block", md: "flex" }}
                variant="contained"
                bgColor="white"
                borderRadius="xl"
                pt={3.5}
                pb={3}
                px={3}
                sx={{ mt: {xs: 3, sm: 5, lg: 10}, boxShadow: 3 }}
              >
                <AboutUsOption
                  icon={fumeurIcon}
                  iconSize="6rem"
                  content={
                    <>
                      Vous êtes un <b>homme</b> de plus de
                      65 ans, <b>fumeur ou ancien
                      fumeur</b> ayant arrêté depuis
                      moins de 5 ans ?
                    </>
                  }
                />
              </MKBox>
              <div>Aucun risque n’est lié à la participation à cette étude.</div>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: { lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack spacing={{ xs: 2, sm: 4 }}>
              <div>Vous devrez :</div>
              <AboutUsOption
                icon={gobeletIcon}
                iconSize="3rem"
                content={
                  <>
                    <b>Collecter vos urines :</b> 3 échantillons d'urine sur une période de 2 ans dans un laboratoire près de chez vous.
                  </>
                }
              />
              <AboutUsOption
                icon={discussionIcon}
                iconSize="3rem"
                content={
                  <>
                      <b>Répondre à un questionnaire</b> avec nos infirmières de recherche pour détecter d’éventuels signes de cancer de la vessie.
                  </>
                }
              />
              <AboutUsOption
                icon={medecinIcon}
                iconSize="3rem"
                content={
                  <>
                      Consulter votre médecin et un urologue en cas de symptômes évocateurs d’un cancer.
                  </>
                }
              />
              <div>
                <MKBox display="flex" alignItems="center" mt={2}>
                  <MKButton color="vdx_green" size="large" onClick={() => window.open(studyLink, "_blank")}>
                    Vérifier mon éligibilité
                  </MKButton>
                </MKBox>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PatientsPage;