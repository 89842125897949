// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "vdx/components/MKTypography";

import FilledInfoCard from "vdx/components/FilledInfoCard";

function ObjectivePage({ studyLink }) {
  return (
    <MKBox component="section" py={{ xs: 5, md: 15 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h1" my={1} color="vdx_grey">
              À ce jour, il n'existe aucun test de dépistage pour le cancer de la vessie !
            </MKTypography>
            <MKTypography variant="body1" mb={2} color="vdx_grey">
            Pourtant, ce cancer, encore peu médiatisé, est agressif et en
            progression. C'est pour cette raison que nous menons
            actuellement l'étude clinique “VisioCyt Screening”, visant à
            développer un <b>test non invasif pour le détecter à un stade
            précoce sur les personnes asymptomatiques afin
            d'augmenter les chances de guérison.</b>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <FilledInfoCard
              title="Aidez-nous à développer le premier test de dépistage !"
              description="Et faites partie des 4000 participants recrutés pour cette étude sur l’ensemble du territoire."
              action={{
                type: "external",
                route: studyLink,
                color: "info",
                label: "Participer à l’étude",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ObjectivePage;