// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "vdx/components/MKButton";
import MKTypography from "vdx/components/MKTypography";

import colors from "vdx/assets/theme/base/colors"

function HelpUsPage({ studyLink }) {

  return (
      <MKBox
        component="section" py={{ xs: 5, md: 15 }}
        display="flex"
        alignItems="center"
        sx={{
          backgroundColor: colors.vdx_green.main
        }}
      >
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Aidez-nous à développer le premier test de dépistage du cancer de la vessie
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
              Chaque participation compte pour améliorer la lutte contre cette maladie.
            </MKTypography>
            <MKButton color="vdx_grey" size="large" onClick={() => window.open(studyLink, "_blank")}>
              Participer à l’étude
            </MKButton>
          </Grid>
      </MKBox>
  );
}

export default HelpUsPage;